import 'leaflet';

const mappe = document.querySelectorAll('.mappa__el');
const markerIcon = L.divIcon({
    html: `
    <svg
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 34.29 48">
        <circle cx="17.14" cy="17.14" r="3.43"/>
        <path d="M17.14 0C7.69 0 0 7.36 0 16.39c0 4.3 1.96 10.03 5.83 17.01a125.5 125.5 0 0 0 8.57 13.2 3.4 3.4 0 0 0 5.48 0 125.5 125.5 0 0 0 8.57-13.2c3.86-6.98 5.82-12.71 5.82-17.01C34.29 7.36 26.6 0 17.14 0Zm0 24a6.86 6.86 0 1 1 0-13.72 6.86 6.86 0 0 1 0 13.72Z"/>
    </svg>
    `,
    className: 'mappa__icon',
    iconSize: [34.29, 48],
    iconAnchor: [17.14, 48],
});

mappe.forEach(mappa => {
    let mappaId = mappa.getAttribute('id');

    let mappaLat = mappa.getAttribute('data-lat');
    let mappaLng = mappa.getAttribute('data-lng');


    var map = L.map(mappaId, {
        center: [mappaLat, mappaLng],
        zoom: 15,
        attributionControl: true,
    });

    map.attributionControl.setPrefix('')

    if (mappa.hasAttribute('data-marker-lat')) {
        let markerLat = mappa.getAttribute('data-marker-lat');
        let markerLng = mappa.getAttribute('data-marker-lng');

        L.marker([markerLat, markerLng], { icon: markerIcon }).addTo(map);
    }

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
});
