const body = document.body;
const navbar = document.querySelector('.navbar');
const hamburger = document.querySelector('.navbar__hamburger');
const nav = document.querySelector('.nav');
const closeButton = document.querySelector('.nav__close');

var navIsOpen = false;

function openNav() {
    nav.style.display = 'flex';

    navIsOpen = true;

    setTimeout(() => {
        body.classList.add('nav_is-open');
    }, 20);
}

function closeNav() {
    let navStyle = window.getComputedStyle(nav);
    let navTransitionDuration = navStyle.getPropertyValue('transition-duration');

    nav.style.display = 'flex';
    body.classList.remove('nav_is-open');

    navIsOpen = false;

    setTimeout(() => {
        nav.removeAttribute('style');
    }, (parseFloat(navTransitionDuration) * 1000));
}

hamburger.addEventListener('click', () => {
    if (navIsOpen == false && !nav.hasAttribute('style')) {
        openNav();
    }
})

closeButton.addEventListener('click', () => {
    if (navIsOpen == true && nav.hasAttribute('style')) {
        closeNav();
    }
})