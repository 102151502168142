import './components/resize';
import './components/carousel';
import './components/mappa';
import './components/search';
import './components/nav';
import './components/orari';
import './components/filters';
import '../../node_modules/sharer.js/sharer';
import './components/ajax-search';
import './components/langSwitcher';

// Rimozione classe no-js
document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');

console.log('Bella per te!');