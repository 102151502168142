const form = document.querySelector('.filters__form');
const filtri = document.querySelectorAll('.filters__cbox');
const clearerButton = document.querySelector('.filters__button_clear');

if (filtri && clearerButton) {
    clearerButton.addEventListener('click', (e) => {
        e.preventDefault();
        filtri.forEach(filtro => {
            if (filtro.checked) {
                filtro.checked = false;
            }
        });
        
        setTimeout(() => {
            form.submit();
        }, 100);
    })
}