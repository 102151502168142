const body = document.body;
const navButton = document.querySelector('.navbar__searchButton');
const searchBox = document.querySelector('.search');
const searchClose = document.querySelector('.search__closeButton');
const searchInput = document.querySelector('.search__input');

var searchIsOpen = false;

navButton.addEventListener('click', function() {
    openSearchBox();
});

searchBox.addEventListener('click', (e) => {
    if (e.target == searchBox) {
        closeSearchBox();
    }
});

searchClose.addEventListener('click', () => {
    closeSearchBox();
});

function openSearchBox() {
    if (searchIsOpen == false) {
        searchBox.style.display = 'block';
        searchIsOpen = true;

        searchInput.focus();

        setTimeout(() => {
            body.classList.add('search_is-open');
        }, 20);
    }
}

function closeSearchBox() {
    if (searchIsOpen == true) {
        body.classList.remove('search_is-open');
        searchBox.style.display = 'block';

        searchIsOpen = false;

        searchBox.addEventListener('transitionend', () => {
            searchBox.removeAttribute('style');
        }, { once: true });
    }
}