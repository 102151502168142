import Swiper, { Navigation, Autoplay } from 'swiper';

const swiper = new Swiper('.swiper', {
    // configure Swiper to use modules
    modules: [Navigation, Autoplay],
    loop: true,

    speed: 600,

    navigation: {
        prevEl: '.carousel__nav_prev',
        nextEl: '.carousel__nav_next',
    },

    // autoplay: {
    //     delay: 7000
    // }
});