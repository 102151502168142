const inputField = document.querySelector('#keyword');
const search = document.querySelector('.search');

if (inputField) {
    inputField.addEventListener('keyup', () => {
        deco_ajax_search();
        if (inputField.value.length > 2) {
            search.classList.add('search_showing');
            search.classList.remove('search_hiding');
        } else {
            search.classList.remove('search_showing');
            search.classList.add('search_hiding');
        }
    });
}

function deco_ajax_search() {
    var xhr = new XMLHttpRequest();
    var url = '/wp-admin/admin-ajax.php';
    var keyword = document.getElementById('keyword').value;

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            document.getElementById('datafetch').innerHTML = xhr.responseText;
        }
    };

    var params = 'action=data_fetch&keyword=' + encodeURIComponent(keyword);
    xhr.send(params);
}