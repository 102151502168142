const orario = document.querySelector('.orario');
const orarioLink = document.querySelector('.postInfo__el_orari a');

if (orarioLink && orario) {
    orarioLink.addEventListener('click', (e) => {
        e.preventDefault();
        
        orario.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        })
    })
}