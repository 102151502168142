const langSelector = document.querySelector(".langSelector");
const langSelector__button = document.querySelector(".langSelector__button");
const langSelector__dropdown = document.querySelector(
    ".langSelector__dropdown"
);

if (langSelector) {
    langSelector__button.addEventListener("click", function (e) {
        langSelector__dropdown.classList.add("langSelector__dropdown_is-open");
    });

    document.addEventListener("click", function (e) {
        var targetElement = e.target;

        // Verifica se il clic è avvenuto all'interno di langSelector__dropdown
        if (!targetElement.closest(".langSelector")) {
            langSelector__dropdown.classList.remove(
                "langSelector__dropdown_is-open"
            );
        }
    });
}
